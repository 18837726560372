import React from 'react';
import '../CSS/Footer.css';
import { OptionProvider, OptionSelector } from './SubscriptionLevel.js';

const Footer = () => {
  return (
    <footer className="footer">
      <h2 className="header">Contact Us</h2>
      <div className="footer-content">
      <div className="links-list">
          {/* Add your links here */}
          <ul className="lists">
          <li><a href="/contactus">Request for Intelligence (RFI)</a></li>
          <li><a href="/contactus">Frequently Asked Questions</a></li>
          <li><a href="/privacy">Privacy & Legal</a></li>
          </ul>
        </div>

      <div className="line"></div>
        <div className="contact-info">
          {/* Add your contact information here */}
          <p>5235 North Front Street </p>
          <p>Harrisburg, PA 17110</p>
          <p>watchroom@ina-inc.com </p>
          <p>+1 717-921-2110</p>
        </div>
      <div className="line"></div>

        <div className="hours">
          <p> Hours </p>
          <p>8:00AM to 4:30PM EST</p>
          <p>Monday – Friday</p>

        </div>

      </div>
      <div className="copyright-info">
        {/* Add your copyright information here */}
        &copy; 2024 Information Network Associates INC. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;