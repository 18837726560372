
  const msalConfig = {
    auth: {
      clientId: 'b6aef8dc-fbfe-4e96-891c-a991e0c07e26',
      authority: 'https://login.microsoftonline.com/e5d504cc-2e13-458a-bec9-f48989de76f0',
      redirectUri : 'https://www.orionreporting.com/'
    },
    cache: {
        cacheLocation: 'localStorage',
    },
  };
  
  export default msalConfig;
  
  