// Storage.js
let storage = {};

export const setStorage = (key, value) => {
  storage[key] = value;
};

export const getStorage = (key) => {
  return storage[key];
};
