import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import '../CSS/Home.css';
import HomeReportsGrid from '../Components/HomeReportGrid.js'
import Upcoming from '../Components/UpcommingReportGrid.js'
import Mapimage from '../Images/map.png';
import { Link } from 'react-router-dom';
import HomeHighlights from '../Components/HomeHighlights.js';
import Banner from '../Components/Banner.js';


const Home = () => {  

  const [access_token, setAccessToken] = useState('');
    
  useEffect(() => {
      // Fetch reports data from your server
      fetch('/api/hello')
          .then((response) => response.json())
          .then((data) => {
              setAccessToken(data.accessToken);
          })
          .catch((error) => {
              console.error('Error fetching reports data:', error);
          });
  }, []);

  return (
    <div>
      <Banner />
      <div className="background-image">
        
      <style>
        {`
            iframe {
                border: none;
            }
        `}
        </style>
          
        {access_token && (
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: 'ed42001a-450a-4332-8b82-397861324bbd',
                    embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=ed42001a-450a-4332-8b82-397861324bbd&groupId=8462ee1f-e8db-4f03-bba9-021eabab566f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19S',
                    accessToken: access_token,
                    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                    pageName : 'ReportSection63e499308fa8b9aa93f8',
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            }
                        },
                        layoutType: models.LayoutType.Single,
                        navContentPaneEnabled: false, // This hides the entire page selector
                        background: models.BackgroundType.Transparent,
                        
                    }
                }}

                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }

                cssClassName={"reportClassHome"}

                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                }}
            />
            )}
      
      </div>

      <div className="columns">
        {/* Recent Reports */}
        <div className="column">
          <h2>Recent Reports</h2>
            <div>
                <HomeReportsGrid /> 
            </div>
        </div>

        {/* Top Reports */}
        <div className="column">
          <h2>Upcoming Event Reports</h2>
          {/* Content for Top Reports */}
          <div>
          <Upcoming />
          </div>
        </div>

        {/* Upcoming Event Reports */}
        <div className="column">
          <h2>Spotlights</h2>
            <div>
            <HomeHighlights />
              
            </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
