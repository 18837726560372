import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import '../CSS/PowerBI.css';

const PowerBI = () => {
    const [access_token, setAccessToken] = useState('');
    
    useEffect(() => {
        // Fetch reports data from your server
        fetch('/api/hello')
            .then((response) => response.json())
            .then((data) => {
                setAccessToken(data.accessToken);
            })
            .catch((error) => {
                console.error('Error fetching reports data:', error);
            });
    }, []);

    

    return (
        <div className="power-bi">
            <h1 className="stats-header">Statistics</h1>
            <div className="box">
                <p><i>Near real-time statistics and charts powered by data sourced from INA’s proprietary database. These interactive charts allow users to explore and comprehend the dynamics of ongoing activist campaigns and intelligence reports.</i></p>
            </div>

            <style>
                {`
                    iframe {
                        border: none;
                    }
                `}
            </style>
          
        {access_token && (
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: '5d14b040-30bd-4655-a270-334e4afaf546',
                    embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=5d14b040-30bd-4655-a270-334e4afaf546&groupId=8462ee1f-e8db-4f03-bba9-021eabab566f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
                    accessToken: access_token,
                    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                    pageName : 'ReportSectionb9f969caf36ee4e18e90',
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            }
                        },
                        layoutType: models.LayoutType.Single,
                        //navContentPaneEnabled: false, // This hides the entire page selector
                        background: models.BackgroundType.Transparent,
                        
                    }
                }}

                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }

                cssClassName={"reportClass"}

                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                }}
            />
            )}
        </div>
    );

}

export default PowerBI;