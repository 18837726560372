// ReportsHomeGrid.js
import React, { useEffect, useState } from 'react';
import '../CSS/ReportsGrid.css';

const ReportsGrid = ({ }) => {
    
const [reports, setReports] = useState([]);

  useEffect(() => {
    // Fetch reports data from your server
    fetch('/api/hello')
      .then((response) => response.json())
      .then((data) => {
        setReports(data.reports.slice(0, 10));
      })
      .catch((error) => {
        console.error('Error fetching reports data:', error);
      });
  }, []);

  console.log(reports);

  return (
    <div className="reports-grid">
      <div className="reports-header">
        <div className="header-item">Title</div>
        <div className="header-item">Report Date</div>
        <div className="header-item">Country</div>
        <div className="header-item">Sector</div>
      </div>
      {reports.map((report) => (
        <div className="report-row" key={report.fields.id}>
          {report.fields.Links1 ? (
            <a
              href={report.fields.Links1} // Set the href to the Links1 column value
              target="_blank" // Open the link in a new tab/window
              rel="noopener noreferrer"
              className="report-item report-title" // Add a CSS class for styling
            >
              {report.fields.Title}
            </a>
          ) : (
            <div className="report-item">{report.fields.Title}</div>
          )}
          <div className="report-item">{report.fields.field_1_formatted}</div>
          <div className="report-item">{report.fields.field_3}</div>
          <div className="report-item">{report.fields.field_6}</div>
        </div>
      ))}
    </div>
  );
};

export default ReportsGrid;