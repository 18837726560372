import React, { useEffect, useState } from 'react';
import '../CSS/ReportsGrid.css';

const ReportsGrid = ({
  searchTerm,
  selectedSector,
  groupResponsible,
  targetedEntity,
  selectedDropdownFilter,
  compName,
  startDate,
  endDate,
  selectedIndustryFilter
}) => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    // Fetch reports data from your server
    fetch('/api/hello')
      .then((response) => response.json())
      .then((data) => {
        setReports(data.reports);
      })
      .catch((error) => {
        console.error('Error fetching reports data:', error);
      });
  }, []);

  const filterReports = (
    reports,
    searchTerm,
    selectedSector,
    groupResponsible,
    targetedEntity,
    selectedDropdownFilter,
    compName,
    startDate,
    endDate,
    selectedIndustryFilter
  ) => {
    return reports.filter((report) => {
      // Additional initial filter by SpecCompanyHidden
      const companyMatches = !compName || report.fields.SpecCompanyHidden === compName;

      // Your existing filtering logic
      const sector = report.fields.field_6 || [];
      const titleMatches = !searchTerm || report.fields.Title?.toLowerCase().includes(searchTerm.toLowerCase());
      const groupResponsibleMatches =
        !groupResponsible ||
        (report.fields.field_10 &&
          report.fields.field_10.toLowerCase().includes(groupResponsible.toLowerCase()));
      const targetedEntityMatches =
        !targetedEntity ||
        (report.fields.field_11 &&
          report.fields.field_11.toLowerCase().includes(targetedEntity.toLowerCase()));
      const dropdownFilterMatches =
        !selectedDropdownFilter ||
        (report.fields.field_9 &&
          report.fields.field_9.toLowerCase().includes(selectedDropdownFilter.toLowerCase()));

      // Sector match logic
      const sectorMatches =
        !selectedSector ||
        sector.some((sec) => sec.toLowerCase() === selectedSector.toLowerCase());

      // Date filtering logic
      const reportDate = new Date(report.fields.field_1_formatted);
      const startDateMatches = !startDate || reportDate >= new Date(startDate);
      const endDateMatches = !endDate || reportDate <= new Date(endDate);

      // Industry filtering logic
      const industryMatches =
        !selectedIndustryFilter ||
        (report.fields.ARIndustry &&
          report.fields.ARIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.ESIndustry &&
          report.fields.ESIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.PharmaIndustry &&
          report.fields.PharmaIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase()));

      // Apply all filters
      return (
        companyMatches &&
        titleMatches &&
        sectorMatches &&
        groupResponsibleMatches &&
        targetedEntityMatches &&
        dropdownFilterMatches &&
        startDateMatches &&
        endDateMatches &&
        industryMatches
      );
    });
  };

  // Apply filters
  const filteredReports = filterReports(
    reports,
    searchTerm,
    selectedSector,
    groupResponsible,
    targetedEntity,
    selectedDropdownFilter,
    compName,
    startDate,
    endDate,
    selectedIndustryFilter
  );

  return (
    <div className="reports-grid">
      <div className="reports-header">
        <div className="header-item">Title</div>
        <div className="header-item">Report Date</div>
        <div className="header-item">Incident Date</div>
        <div className="header-item">Country</div>
        <div className="header-item">Sector</div>
      </div>
      {filteredReports.map((report) => (
        <div className="report-row" key={report.fields.id}>
          {report.fields.Links1 ? (
            <a
              href={report.fields.Links1}
              target="_blank"
              rel="noopener noreferrer"
              className="report-item report-title"
            >
              {report.fields.Title}
            </a>
          ) : (
            <div className="report-item">{report.fields.Title}</div>
          )}
          <div className="report-item">{report.fields.field_1_formatted}</div>
          <div className="report-item">{report.fields.field_2_formatted}</div>
          <div className="report-item">{report.fields.field_3}</div>
          <div className="report-item">{report.fields.field_6}</div>
        </div>
      ))}
    </div>
  );
};

export default ReportsGrid;
