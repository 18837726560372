import React, { useEffect, useState } from 'react';
import '../CSS/Highlights.css';

//This is not used. If another bucket is needed this page can be used. Need to be readded to the 
//App.js route and then navigation on the site

const Highlights = () => {
    const [GeneralIntel, setGeneralIntel] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading

    useEffect(() => {
        // Fetch reports data from your server
        fetch('/api/hello')
            .then((response) => response.json())
            .then((data) => {
                setGeneralIntel(data.GeneralIntel);
                setFilteredData(data.GeneralIntel); // Set filteredData with initial data
                setLoading(false); // Data is fetched, set loading to false
            })
            .catch((error) => {
                console.error('Error fetching reports data:', error);
                setLoading(false); // Set loading to false in case of error
            });
    }, []);

    // Function to handle search input change
    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        // Filter the GeneralIntel array only if search term is present
        if (searchTerm.trim() !== '') {
            const filtered = GeneralIntel.filter((item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredData(filtered); // Update filtered data based on search
        } else {
            setFilteredData(GeneralIntel); // Show all data if search term is empty
        }
    };

    return (
        <div className="highlights">
            <h1>Financials</h1>
            <h2>Here is you blurb</h2>
            {/* Search input */}
            Search:&nbsp;
            <input
                type="text"
                placeholder="Search by title"
                value={searchTerm}
                onChange={handleSearch}
            />
            <ul className="list-item">
                {filteredData.map((item, index) => (
                    <li key={index}>
                        <a href={item.webUrl} target="_blank" rel="noopener noreferrer">{item.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Highlights;
