import React, { useEffect, useState } from 'react';
import '../CSS/HomeHighlights.css';

const HomeHighlights = () => {
    const [Spotlight, setSpotlight] = useState([]);

    useEffect(() => {
        // Fetch reports data from your server
        fetch('/api/hello')
            .then((response) => response.json())
            .then((data) => {
                setSpotlight(data.Spotlight.slice(0,10));

            })
            .catch((error) => {
                console.error('Error fetching reports data:', error);
            });
    }, []);

    return (
        <div className="home-highlights">
            <ul className="list-item-home">
                {Spotlight.map((item, index) => (
                    <li key={index}>
                        <a href={item.webUrl} target="_blank" rel="noopener noreferrer">{item.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default HomeHighlights;