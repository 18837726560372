// Navigation.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <div className="navigation" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span className="nav-item">Regions</span>
      {showDropdown && (
        <div className="dropdown">
          <ul>
          <li>
            <Link to="/regions/North America" className="dropdown-item">North America</Link>
          </li>
          <li>
            <Link to="/regions/Latin America" className="dropdown-item">Latin America</Link>
          </li>
          <li>
            <Link to="/regions/Africa" className="dropdown-item">Africa</Link>
          </li>
          <li>
            <Link to="/regions/Middle East" className="dropdown-item">Middle East</Link>
          </li>
          <li>
            <Link to="/regions/Western Europe" className="dropdown-item">Western Europe</Link>
          </li>
          <li>
            <Link to="/regions/Eastern Europe" className="dropdown-item">Eastern Europe</Link>
          </li>
          <li>
            <Link to="/regions/Asia" className="dropdown-item">Asia</Link>
          </li>
          <li>
            <Link to="/regions/Oceania" className="dropdown-item">Oceania</Link>
          </li>
          {/* Add other regions */}
        </ul>
        </div>
      )}
    </div>
  );
};

export default Navigation;
