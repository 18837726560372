import React from 'react';
import { useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import msalConfig from '../auth/msalConfig.js';

const msalInstance = new PublicClientApplication(msalConfig);

const Home = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  const login = () => {
    if (!isAuthenticated) {
      instance.loginPopup();
    }
  };

  return (
    <div>
      <h1>Please Login</h1>
      <h2>If unable to login contact your IT department</h2>
      {!isAuthenticated && (
        <div className='login-section'>
          <button className='login-btn' onClick={login}>Login</button>
        </div>
      )}
    </div>
  );
}

export default Home;
