import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import '../CSS/Highlights.css';

const Highlights = () => {
    const [WeeklyAR, setWeeklyAR] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading
    const [access_token, setAccessToken] = useState('');

    useEffect(() => {
        // Fetch reports data from your server
        fetch('/api/hello')
            .then((response) => response.json())
            .then((data) => {
                setWeeklyAR(data.WeeklyAR);
                setFilteredData(data.WeeklyAR); // Set filteredData with initial data
                setLoading(false); // Data is fetched, set loading to false
                setAccessToken(data.accessToken);
            })
            .catch((error) => {
                console.error('Error fetching reports data:', error);
                setLoading(false); // Set loading to false in case of error
            });
    }, []);

    // Function to handle search input change
    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        // Filter the WeeklyAR array only if search term is present
        if (searchTerm.trim() !== '') {
            const filtered = WeeklyAR.filter((item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredData(filtered); // Update filtered data based on search
        } else {
            setFilteredData(WeeklyAR); // Show all data if search term is empty
        }
    };

    return (
        <div className="highlights">
            <h1>Weekly Intsum AR</h1>

            <style>
                {`
                    iframe {
                        border: none;
                    }
                `}
            </style>

            {access_token && (
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: '3655080c-92e3-4b2e-ac09-afd3456c262a',
                    embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=3655080c-92e3-4b2e-ac09-afd3456c262a&groupId=8462ee1f-e8db-4f03-bba9-021eabab566f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
                    accessToken: access_token,
                    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            }
                        },
                        layoutType: models.LayoutType.Single,
                        navContentPaneEnabled: false, // This hides the entire page selector
                        background: models.BackgroundType.Transparent,
                        
                    }
                }}

                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }

                cssClassName={"reportClassar"}

                getEmbeddedComponent={(embeddedReport) => {
                    window.Report = embeddedReport;
                }}
            />
            )}

            {/* Search input */}
            Search:&nbsp; 
            <input
                type="text"
                placeholder="Search by title"
                value={searchTerm}
                onChange={handleSearch}
            />
            <ul className="list-item">
                {filteredData.map((item, index) => (
                    <li key={index}>
                        <a href={item.webUrl} target="_blank" rel="noopener noreferrer">{item.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Highlights;
