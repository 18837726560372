import React, { useEffect, useState } from 'react';
import { PowerBIEmbed} from 'powerbi-client-react';
import {models} from 'powerbi-client';
import OrgsList from '../Components/Orgslist.js';
import '../CSS/Orgs.css'
import { Link } from 'react-router-dom';


const Orgs = () => {

  //_x0054_op25
    const [orgs, setorgs] = useState([]);
    const [top25, settop25] = useState([]);
    const [access_token, setAccessToken] = useState('');

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedLetter, setSelectedLetter] = useState('');
 
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
    };
 
    const handleSectorChange = (event) => {
      setSelectedSector(event.target.value);
    };
 
    const handleLetterChange = (letter) => {
      setSelectedLetter(letter);
  };
 
    const clearFilters = () => {
        handleSearch({ target: { value: '' } });
        handleSectorChange({ target: { value: '' } });
        handleLetterChange('');
      };

    useEffect(() => {
      // Fetch reports data from your server
      fetch('/api/hello')
        .then((response) => response.json())
        .then((data) => {
          setorgs(data.orgs);
          settop25(data.orgs.filter((org) => org.fields._x0054_op25));
          setAccessToken(data.accessToken);
        })
        .catch((error) => {
          console.error('Error fetching reports data:', error);
        });
    }, []);


    return (
        <div className="orgs">
            <h1>Activist Organizations</h1>
            {/* Search input */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <h3>Name Search:</h3>
                <input
                    type="text"
                    placeholder="Search by Name"
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ marginRight: '1rem' }}
                />
                {/* Sector filter */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <h3>Sector Filter:</h3>
                    <label style={{ color: selectedSector !== 'AR' ? 'white' : '#cab069' }}>
                    <input
                        type="radio"
                        value="AR"
                        checked={selectedSector === 'AR'}
                        onChange={handleSectorChange}
                    />
                    Animal Rights
                    </label>
                    <label style={{ color: selectedSector !== 'ES' ? 'white' : '#cab069' }}>
                    <input
                        type="radio"
                        value="ES"
                        checked={selectedSector === 'ES'}
                        onChange={handleSectorChange}
                    />
                    Energy Sector
                    </label>
                    <label style={{ color: selectedSector !== 'PHARMA' ? 'white' : '#cab069' }}>
                    <input
                        type="radio"
                        value="PHARMA"
                        checked={selectedSector === 'PHARMA'}
                        onChange={handleSectorChange}
                    />
                    Pharma
                    </label>
                </div>
                {/* Clear filters button */}
                <button onClick={clearFilters}>Clear Filters</button>
                </div>

                <div className="list-container">
      <div className="list-item">
        <div className="all-groups">
          <h1>Groups A-Z</h1>
          <div className="alphabet-filter">
                <span
                    className={`letter ${selectedLetter === '' ? 'selected' : ''}`}
                    onClick={() => handleLetterChange('')}
                >
                    
                </span>
                {'#ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter) => (
                    <span
                        key={letter}
                        className={`letter ${selectedLetter === letter ? 'selected' : ''}`}
                        onClick={() => handleLetterChange(letter)}
                    >
                        {letter}
                    </span>
                ))}
            </div>
          <OrgsList 
            orgs={orgs}
            searchTerm={searchTerm}
            sectorSelected={selectedSector}
            selectedLetter={selectedLetter}
            handleSearch={handleSearch}
            handleSectorChange={handleSectorChange}
          />
        </div>
        <div className="top-25">
        <h1>Groups By Activity</h1>
        <style>
        {`
            iframe {
                border: none;
            }
        `}
        </style>
          <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: '5d14b040-30bd-4655-a270-334e4afaf546',
                    embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=5d14b040-30bd-4655-a270-334e4afaf546&groupId=8462ee1f-e8db-4f03-bba9-021eabab566f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
                    accessToken: access_token,
                    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                    pageName : 'ReportSection707abbb7419243da4c42',
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            }
                        },
                        layoutType: models.LayoutType.Single,
                        navContentPaneEnabled: false, // This hides the entire page selector
                        background: models.BackgroundType.Transparent,
                       
                    }
                }}
 
                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }
 
                cssClassName={"reportClassOrgs"}
 
                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                }}
            />
        </div>
      </div>
    </div>

        </div>
    );

}

export default Orgs;