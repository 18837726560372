import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../CSS/RegionDetails.css'

const RegionDetails = () => {
  const { region } = useParams();
  const [regionReports, setRegionReports] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSector, setSelectedSector] = useState('');

  const [groupResponsible, setGroupResponsible] = useState('');
  const [targetedEntity, setTargetedEntity] = useState('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const [selectedDropdownFilter, setSelectedDropdownFilter] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedIndustryFilter, setSelectedIndustryFilter] = useState('');


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSectorChange = (event) => {
    const sector = event.target.value;
    setSelectedSector(sector === selectedSector ? '' : sector); // Toggle the sector
  };

  const handleGroupResponsibleChange = (event) => {
    setGroupResponsible(event.target.value);
  };

  const handleTargetedEntityChange = (event) => {
    setTargetedEntity(event.target.value);
  }

  const handleClear = () => {
    setSearchTerm('');
    setSelectedSector('');
    setGroupResponsible('');
    setTargetedEntity('');
    setSelectedDropdownFilter('');
    setStartDate('');
    setEndDate('');
    setSelectedIndustryFilter('');
  };

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  const applyDropdownFilter = (event) => {
    setSelectedDropdownFilter(event.target.value);
    // Apply logic based on the selected dropdown filter (e.g., trigger API call with the filter)
    console.log(`Applying dropdown filter: ${event.target.value}`);
  };

  const applyIndustryFilter = (event) => {
    setSelectedIndustryFilter(event.target.value);
    // Apply logic based on the selected industry filter (e.g., trigger API call with the filter)
    console.log(`Applying industry filter: ${event.target.value}`);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };


  useEffect(() => {
    // Fetch reports data from your server or your data source
    fetch('/api/hello') // Replace with your API endpoint
      .then((response) => response.json())
      .then((data) => {
        // Filter reports based on the region
        const reportsForRegion = data.reports.filter(
          (report) => report.fields.Region0 === region
        );
        setRegionReports(reportsForRegion);
      })
      .catch((error) => {
        console.error('Error fetching reports data:', error);
      });
  }, [region]); // Fetch data whenever the region parameter changes

  const filterReports = (reports, searchTerm, selectedSector, groupResponsible, targetedEntity, selectedDropdownFilter, startDate, endDate, selectedIndustryFilter) => {
    return reports.filter((report) => {
      const sector = report.fields.field_6 || [];
      const titleMatches = !searchTerm || report.fields.Title?.toLowerCase().includes(searchTerm.toLowerCase());
      const groupResponsibleMatches =
        !groupResponsible ||
        (report.fields.field_10 &&
          report.fields.field_10.toLowerCase().includes(groupResponsible.toLowerCase()));
      const targetedEntityMatches =
        !targetedEntity ||
        (report.fields.field_11 &&
          report.fields.field_11.toLowerCase().includes(targetedEntity.toLowerCase()));
      const dropdownFilterMatches =
        !selectedDropdownFilter ||
        (report.fields.field_9 &&
          report.fields.field_9.toLowerCase().includes(selectedDropdownFilter.toLowerCase()));
  
      // Date filtering logic
      const reportDate = new Date(report.fields.field_1_formatted);
      const startDateMatches = !startDate || reportDate >= new Date(startDate);
      const endDateMatches = !endDate || reportDate <= new Date(endDate);
  
      // Industry filtering logic
      const industryMatches =
        !selectedIndustryFilter ||
        (report.fields.ARIndustry &&
          report.fields.ARIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.ESIndustry &&
          report.fields.ESIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.PharmaIndustry &&
          report.fields.PharmaIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase()));
  
      if (!selectedSector && !searchTerm && !groupResponsible && !targetedEntity && !selectedDropdownFilter) {
        return startDateMatches && endDateMatches && industryMatches; // Return all reports when no filters are selected
      }
  
      if (selectedSector) {
        const sectorMatches = sector.some((sec) => sec.toLowerCase() === selectedSector.toLowerCase());
        return titleMatches && sectorMatches && groupResponsibleMatches && targetedEntityMatches && dropdownFilterMatches && startDateMatches && endDateMatches && industryMatches;
      }
  
      return titleMatches && groupResponsibleMatches && targetedEntityMatches && dropdownFilterMatches && startDateMatches && endDateMatches && industryMatches;
    });
  };

 // Apply filters
 const filteredReports = filterReports(
    regionReports,
    searchTerm,
    selectedSector,
    groupResponsible,
    targetedEntity,
    selectedDropdownFilter,
    startDate,
    endDate,
    selectedIndustryFilter
  );

  return (
    <div className="regions">
      <div className={`regions ${region.toLowerCase().replace(/\s/g, '-')}`}>
      <h1>{`Reports for ${region}`}</h1>

      <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20vh', // This sets the height to the full viewport height
    marginTop: '5vh',
    marginBottom: '7vh'
  }}>
  {/* Search and Sector filter */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
    {/* Search input */}
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <h3>Search:</h3>
      <input
        type="text"
        placeholder="Search by Title"
        value={searchTerm}
        onChange={handleSearch}
      />
 
      {/* Sector filter */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h3>Sector Filter:</h3>
        {/* ... Radio buttons for sectors */}
        <label style={{ color: selectedSector !== 'Animal Rights' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Animal Rights"
              checked={selectedSector === 'Animal Rights'}
              onChange={handleSectorChange}
            />
            <span>Animal Rights</span>
          </label>
          <label style={{ color: selectedSector !== 'Energy Sector' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Energy Sector"
              checked={selectedSector === 'Energy Sector'}
              onChange={handleSectorChange}
            />
            <span>Energy Sector</span>
          </label>
          <label style={{ color: selectedSector !== 'Pharmaceuticals' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Pharmaceuticals"
              checked={selectedSector === 'Pharmaceuticals'}
              onChange={handleSectorChange}
            />
            <span>Pharma</span>
          </label>
          <label style={{ color: selectedSector !== 'Critical Insights' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Critical Insights"
              checked={selectedSector === 'Critical Insights'}
              onChange={handleSectorChange}
            />
            <span>Critical Insights</span>
          </label>
      </div>
    </div>
 
    {/* Clear button */}
    <button onClick={handleClear} className="clear-button">Clear</button>
  </div>
{/* Advanced search row 1 */}
<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
    {/* Group Responsible search */}
    <label>
      Group Responsible:&nbsp;
      <input
        type="text"
        placeholder="Search by group"
        value={groupResponsible}
        onChange={handleGroupResponsibleChange}
      />
    </label>
 
    {/* Targeted Entity search */}
    <label>
      Targeted Entity:&nbsp;
      <input
        type="text"
        placeholder="Search by targeted entity"
        value={targetedEntity}
        onChange={handleTargetedEntityChange}
      />
    </label>
 
    {/* Type of Report dropdown */}
    <label htmlFor="dropdownFilter">Type of Report:</label>
    <select id="dropdownFilter" value={selectedDropdownFilter} onChange={applyDropdownFilter}>
      <option value="">Select an option</option>
      <option value="protest planned">Protest Planned</option>
      <option value="protest after-action">Protest After-Action</option>
      <option value="direct action">Direct Action</option>
      <option value="general intel">General Intel</option>
      <option value="infiltration">Infiltration</option>
    </select>
  </div>
 
  {/* Advanced search row 2 */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginTop: '1rem', marginBottom: '1rem' }}>
        {/* Targeted Industry dropdown */}
        <label htmlFor="industryFilter">Targeted Industry:</label>
        <select id="industryFilter" value={selectedIndustryFilter} onChange={applyIndustryFilter}>
            <option value="">Select an option</option>
            <option value="Fur and Leather">Fur and Leather</option>
            <option value="Hunting">Hunting</option>
            <option value="Research – CRO">Research – CRO</option>
            <option value="Research – Academic">Research – Academic</option>
            <option value="Research – Breeder">Research – Breeder</option>
            <option value="Research – General">Research – General</option>
            <option value="Research – Pharmaceuticals">Research – Pharmaceuticals</option>
            <option value="Research – Transportation">Research – Transportation</option>
            <option value="Research – Government">Research – Government</option>
            <option value="Research – Cosmetics">Research – Cosmetics</option>
            <option value="Research – Other">Research – Other</option>
            <option value="Agriculture – General">Agriculture – General</option>
            <option value="Agriculture – Transportation">Agriculture – Transportation</option>
            <option value="Agriculture – Government">Agriculture – Government</option>
            <option value="Agriculture – Food Services">Agriculture – Food Services</option>
            <option value="Agriculture – Farms">Agriculture – Farms</option>
            <option value="Agriculture – Processing">Agriculture – Processing</option>
            <option value="Entertainment – Government">Entertainment – Government</option>
            <option value="Entertainment – Sports">Entertainment – Sports</option>
            <option value="Entertainment – Zoo/Park">Entertainment – Zoo/Park</option>
            <option value="Pet Industry">Pet Industry</option>
            <option value="Coal">ES - Coal</option>
            <option value="Logging">ES - Logging</option>
            <option value="Academic">ES - Academic</option>
            <option value="Financing">ES - Financing</option>
            <option value="Government">ES - Government</option>
            <option value="Fossil Fuels">ES - Fossil Fuels</option>
            <option value="Nuclear">ES - Nuclear</option>
            <option value="Renewables">ES - Renewables</option>
            <option value="Anti-Vaccine">Pharma - Anti-Vaccine</option>
            <option value="Access to Medicine">Pharma - Access to Medicine</option>
            <option value="GMO">Pharma - GMO</option>
            {/* Add more options as needed */}
          </select>
 
    {/* Start Date filter */}
    <label>
      Start Date:&nbsp;
      <input
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
      />
    </label>
 
    {/* End Date filter */}
    <label>
      End Date:&nbsp;
      <input
        type="date"
        value={endDate}
        onChange={handleEndDateChange}
      />
    </label>
 
  </div>
</div>

      {/* Display region-specific reports */}
      <div className="reports-grid">
      <div className="reports-header">
        <div className="header-item">Title</div>
        <div className="header-item">Report Date</div>
        <div className="header-item">Incident Date</div>
        <div className="header-item">Country</div>
        <div className="header-item">Sector</div>
      </div>
      {filteredReports.map((report) => (
        <div className="report-row" key={report.Id}>
          {report.fields.Links1 ? (
            <a
              href={report.fields.Links1} // Set the href to the Links1 column value
              target="_blank" // Open the link in a new tab/window
              rel="noopener noreferrer"
              className="report-item report-title" // Add a CSS class for styling
            >
              {report.fields.Title}
            </a>
          ) : (
            <div className="report-item">{report.fields.Title}</div>
          )}
          <div className="report-item">{report.fields.field_1_formatted}</div>
          <div className="report-item">{report.fields.field_2_formatted}</div>
          <div className="report-item">{report.fields.field_3}</div>
          <div className="report-item">{report.fields.field_6}</div>
        </div>
      ))}
    </div>
    </div>
    </div>
  );
};

export default RegionDetails;
