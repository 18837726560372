import React from 'react';
import '../CSS/App.css';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import msalConfig from '../auth/msalConfig.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar.js';
import Reports from '../Pages/Reports.js';
import Home from '../Pages/Home.js';
import Countries from '../Pages/Regions.js';
import Orgs from '../Pages/Orgs.js';
import Login from '../Pages/Login.js';
import OrgDetails from '../Pages/OrgDetails.js';
import RegionDetails from '../Pages/Regionsdetails.js';
import Privacy from '../Pages/Privacy.js';
import Highlights from '../Pages/Highlights.js';
import PowerBI from '../Pages/PowerBi.js';
import Umbrella from '../Pages/Umbrella.js';
import Calendar from '../Pages/Calendar.js';
import Footer from '../Components/Footer.js';
import ContactUs from '../Pages/ContactUs.js';
import Financials from '../Pages/Financial.js';
import WeeklyIntsumAR from '../Pages/WeeklyIntsumAR.js';
import WeeklyIntsumES from '../Pages/WeeklyIntsumES.js';
import Spotlights from '../Pages/Spotlights.js';
import ThreatAssessment from '../Pages/ThreatAssessmentSnapshot.js';
import AnnualAssessment from '../Pages/AnnualAssessment.js';
import Complaints from '../Pages/Complaints.js';
import SecurityPlanning from '../Pages/SecurityPlanning.js';
import ScrollToTop from '../Components/ScrollToTop.js';


const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  return (
    // Wrap the entire app inside OptionProvider
      <Router>
        <div className="App">
          {isAuthenticated && <Navbar />}
          <ScrollToTop />


          <header className="App-header">
            {isAuthenticated ? (
              <Routes>
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/region" element={<Countries />} />
                <Route path="/orgs" element={<Orgs />} />
                <Route path="/orgdetails/:id" element={<OrgDetails />} />
                <Route path="/regions/:region" element={<RegionDetails />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/highlights" element={<Highlights />} />
                <Route path="/powerbi" element={<PowerBI />} />
                <Route path="/:companyName" element={<Umbrella />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/weeklyintsumar" element={<WeeklyIntsumAR />} />
                <Route path="/weeklyintsumes" element={<WeeklyIntsumES />} />
                <Route path="/spotlights" element={<Spotlights />} />
                <Route path="/threatassessmentsnapshot" element={<ThreatAssessment />} />
                <Route path="/annualassessment" element={<AnnualAssessment />} />
                <Route path="/complaints" element={<Complaints />} />
                <Route path="/securityplanning" element={<SecurityPlanning />} />
                <Route path="/financials" element={<Financials />} />
              </Routes>
            ) : (
              <Routes>
                <Route index element={<Login />} />
                <Route path="/login" element={<Login />} />
              </Routes>
            )}
          </header>
          {isAuthenticated && <Footer />}
        </div>
      </Router>
  );
}

function AuthenticatedApp() {
  return (
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  );
}

export default AuthenticatedApp;
