// src/Reports.js
import React, { useState,useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import ReportsGrid from '../Components/ReportsGrid.js';
import '../CSS/Reports.css';
import '../CSS/PowerBI.css';
function Reports() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [groupResponsible, setGroupResponsible] = useState('');
  const [targetedEntity, setTargetedEntity] = useState('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [selectedDropdownFilter, setSelectedDropdownFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedIndustryFilter, setSelectedIndustryFilter] = useState('');
  const [access_token, setAccessToken] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSectorChange = (event) => {
    const sector = event.target.value;
    setSelectedSector(sector === selectedSector ? '' : sector); // Toggle the sector
  };

  const handleGroupResponsibleChange = (event) => {
    setGroupResponsible(event.target.value);
  };

  const handleTargetedEntityChange = (event) => {
    setTargetedEntity(event.target.value);
  }

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleClear = () => {
    setSearchTerm('');
    setSelectedSector('');
    setGroupResponsible('');
    setTargetedEntity('');
    setSelectedDropdownFilter('');
    setStartDate('');
    setEndDate('');
    setSelectedIndustryFilter('');
  };

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(prevState => !prevState);
  };


  const applyDropdownFilter = (event) => {
    setSelectedDropdownFilter(event.target.value);
    // Apply logic based on the selected dropdown filter (e.g., trigger API call with the filter)
    console.log(`Applying dropdown filter: ${event.target.value}`);
  };

  const applyIndustryFilter = (event) => {
    setSelectedIndustryFilter(event.target.value);
    // Apply logic based on the selected industry filter (e.g., trigger API call with the filter)
    console.log(`Applying industry filter: ${event.target.value}`);
  };

    
  useEffect(() => {
      // Fetch reports data from your server
      fetch('/api/hello')
          .then((response) => response.json())
          .then((data) => {
              setAccessToken(data.accessToken);
          })
          .catch((error) => {
              console.error('Error fetching reports data:', error);
          });
  }, []);

  return (
    <div className="reports">
      <h1>Reports Index</h1>

      <style>
                {`
                    iframe {
                        border: none;
                    }
                `}
            </style>
        <div className="stats-ticker">  
        {access_token && (
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: '1a7fd4bc-959b-4fe5-b2e8-36d6b56150b1',
                    embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=1a7fd4bc-959b-4fe5-b2e8-36d6b56150b1&groupId=8462ee1f-e8db-4f03-bba9-021eabab566f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
                    accessToken: access_token,
                    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                    pageName : 'ReportSectionb9f969caf36ee4e18e90',
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            }
                        },
                        layoutType: models.LayoutType.Single,
                        navContentPaneEnabled: false, // This hides the entire page selector
                        background: models.BackgroundType.Transparent,
                        
                    }
                }}

                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }

                cssClassName={"reportClass-ticker"}

                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                }}
            />
            )}
            </div>


            <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20vh', // This sets the height to the full viewport height
    marginTop: '5vh',
    marginBottom: '7vh'
  }}>
  {/* Search and Sector filter */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
    {/* Search input */}
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <h3>Search:</h3>
      <input
        type="text"
        placeholder="Search by Title"
        value={searchTerm}
        onChange={handleSearch}
      />
 
      {/* Sector filter */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h3>Sector Filter:</h3>
        {/* ... Radio buttons for sectors */}
        <label style={{ color: selectedSector !== 'Animal Rights' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Animal Rights"
              checked={selectedSector === 'Animal Rights'}
              onChange={handleSectorChange}
            />
            <span>Animal Rights</span>
          </label>
          <label style={{ color: selectedSector !== 'Energy Sector' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Energy Sector"
              checked={selectedSector === 'Energy Sector'}
              onChange={handleSectorChange}
            />
            <span>Energy Sector</span>
          </label>
          <label style={{ color: selectedSector !== 'Pharmaceuticals' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Pharmaceuticals"
              checked={selectedSector === 'Pharmaceuticals'}
              onChange={handleSectorChange}
            />
            <span>Pharma</span>
          </label>
          <label style={{ color: selectedSector !== 'Critical Insights' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Critical Insights"
              checked={selectedSector === 'Critical Insights'}
              onChange={handleSectorChange}
            />
            <span>Critical Insights</span>
          </label>
      </div>
    </div>
 
    {/* Clear button */}
    <button onClick={handleClear} className="clear-button">Clear</button>
  </div>
{/* Advanced search row 1 */}
<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
    {/* Group Responsible search */}
    <label>
      Group Responsible:&nbsp;
      <input
        type="text"
        placeholder="Search by group"
        value={groupResponsible}
        onChange={handleGroupResponsibleChange}
      />
    </label>
 
    {/* Targeted Entity search */}
    <label>
      Targeted Entity:&nbsp;
      <input
        type="text"
        placeholder="Search by targeted entity"
        value={targetedEntity}
        onChange={handleTargetedEntityChange}
      />
    </label>
 
    {/* Type of Report dropdown */}
    <label htmlFor="dropdownFilter">Type of Report:</label>
    <select id="dropdownFilter" value={selectedDropdownFilter} onChange={applyDropdownFilter}>
      <option value="">Select an option</option>
      <option value="protest planned">Protest Planned</option>
      <option value="protest after-action">Protest After-Action</option>
      <option value="direct action">Direct Action</option>
      <option value="general intel">General Intel</option>
      <option value="infiltration">Infiltration</option>
    </select>
  </div>
 
  {/* Advanced search row 2 */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginTop: '1rem', marginBottom : '1rem' }}>
        {/* Targeted Industry dropdown */}
        <label htmlFor="industryFilter">Targeted Industry:</label>
        <select id="industryFilter" value={selectedIndustryFilter} onChange={applyIndustryFilter}>
            <option value="">Select an option</option>
            <option value="Fur and Leather">Fur and Leather</option>
            <option value="Hunting">Hunting</option>
            <option value="Research – CRO">Research – CRO</option>
            <option value="Research – Academic">Research – Academic</option>
            <option value="Research – Breeder">Research – Breeder</option>
            <option value="Research – General">Research – General</option>
            <option value="Research – Pharmaceuticals">Research – Pharmaceuticals</option>
            <option value="Research – Transportation">Research – Transportation</option>
            <option value="Research – Government">Research – Government</option>
            <option value="Research – Cosmetics">Research – Cosmetics</option>
            <option value="Research – Other">Research – Other</option>
            <option value="Agriculture – General">Agriculture – General</option>
            <option value="Agriculture – Transportation">Agriculture – Transportation</option>
            <option value="Agriculture – Government">Agriculture – Government</option>
            <option value="Agriculture – Food Services">Agriculture – Food Services</option>
            <option value="Agriculture – Farms">Agriculture – Farms</option>
            <option value="Agriculture – Processing">Agriculture – Processing</option>
            <option value="Entertainment – Government">Entertainment – Government</option>
            <option value="Entertainment – Sports">Entertainment – Sports</option>
            <option value="Entertainment – Zoo/Park">Entertainment – Zoo/Park</option>
            <option value="Pet Industry">Pet Industry</option>
            <option value="Coal">ES - Coal</option>
            <option value="Logging">ES - Logging</option>
            <option value="Academic">ES - Academic</option>
            <option value="Financing">ES - Financing</option>
            <option value="Government">ES - Government</option>
            <option value="Fossil Fuels">ES - Fossil Fuels</option>
            <option value="Nuclear">ES - Nuclear</option>
            <option value="Renewables">ES - Renewables</option>
            <option value="Anti-Vaccine">Pharma - Anti-Vaccine</option>
            <option value="Access to Medicine">Pharma - Access to Medicine</option>
            <option value="GMO">Pharma - GMO</option>
            {/* Add more options as needed */}
          </select>
 
    {/* Start Date filter */}
    <label>
      Start Date:&nbsp;
      <input
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
      />
    </label>
 
    {/* End Date filter */}
    <label>
      End Date:&nbsp;
      <input
        type="date"
        value={endDate}
        onChange={handleEndDateChange}
      />
    </label>
 
  </div>
</div>

      {/* Render the ReportsGrid component */}
      <ReportsGrid searchTerm={searchTerm} 
      selectedSector={selectedSector} 
      groupResponsible={groupResponsible}
      targetedEntity={targetedEntity}
      selectedDropdownFilter={selectedDropdownFilter}
      startDate={startDate}
      endDate={endDate}
      selectedIndustryFilter={selectedIndustryFilter}
      />
    </div>
  );
}

export default Reports;
