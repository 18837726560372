import React, { useEffect, useState } from 'react';
import '../CSS/Highlights.css';

const Highlights = () => {
    const [ThreatAssessment, setThreatAssessment] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading

    useEffect(() => {
        // Fetch reports data from your server
        fetch('/api/hello')
            .then((response) => response.json())
            .then((data) => {
                setThreatAssessment(data.ThreatAssessment);
                setFilteredData(data.ThreatAssessment); // Set filteredData with initial data
                setLoading(false); // Data is fetched, set loading to false
            })
            .catch((error) => {
                console.error('Error fetching reports data:', error);
                setLoading(false); // Set loading to false in case of error
            });
    }, []);

    // Function to handle search input change
    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        // Filter the ThreatAssessment array only if search term is present
        if (searchTerm.trim() !== '') {
            const filtered = ThreatAssessment.filter((item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredData(filtered); // Update filtered data based on search
        } else {
            setFilteredData(ThreatAssessment); // Show all data if search term is empty
        }
    };

    return (
        <div className="highlights">
            <h1>Threat Assessment Snapshot</h1>
            <div className="box">
                <p><i>Brief intelligence assessments of potential hazards, vulnerabilities, and threats associated with a particular event or industry conference. The assessments consider various aspects such as location, local activism, and other human activities that might contribute to potential risks.</i></p>
            </div>
            {/* Search input */}
            Search:&nbsp;
            <input
                type="text"
                placeholder="Search by title"
                value={searchTerm}
                onChange={handleSearch}
            />
            <ul className="list-item">
                {filteredData.map((item, index) => (
                    <li key={index}>
                        <a href={item.webUrl} target="_blank" rel="noopener noreferrer">{item.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Highlights;
