import React, { useState } from 'react';
import '../CSS/Banner.css';

const Banner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="banner">
        <p className="disclaimer-text">Intelligence reports and data analytics on this platform are propriety and confidential.</p>
        <span onClick={handleCloseBanner} className="close-button">X</span>
      </div>
      )}
    </>
  );
};

export default Banner;
