import React, { useEffect, useState } from 'react';
import '../CSS/ReportsGrid.css';

const ReportsGrid = () => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    // Fetch reports data from your server
    fetch('/api/hello')
      .then((response) => response.json())
      .then((data) => {
        // Get the current date
        const currentDate = new Date();

        // Filter reports based on the field_2 date field
            const upcomingReports = data.reports.filter((report) => {
                const reportDate = new Date(report.fields.field_2); // Use field_2 as the date field
                return reportDate > currentDate;
  });

        // Sort reports based on the field_2 date field in descending order
            const sortedReports = upcomingReports.sort((a, b) => {
                const dateA = new Date(a.fields.field_2);
                const dateB = new Date(b.fields.field_2);
                return dateA - dateB;
                });

        setReports(sortedReports.slice(0,10));
      })
      .catch((error) => {
        console.error('Error fetching reports data:', error);
      });
  }, []);

  

  return (
    <div className="reports-grid">
      <div className="reports-header">
        <div className="header-item">Title</div>
        <div className="header-item">Event Date</div>
        <div className="header-item">Country</div>
        <div className="header-item">Sector</div>
      </div>
      {reports.map((report) => (
        <div className="report-row" key={report.fields.id}>
          {report.fields.Links1 ? (
            <a
              href={report.fields.Links1}
              target="_blank"
              rel="noopener noreferrer"
              className="report-item report-title"
            >
              {report.fields.Title}
            </a>
          ) : (
            <div className="report-item">{report.fields.Title}</div>
          )}
          <div className="report-item">{report.fields.field_2_formatted}</div>
          <div className="report-item">{report.fields.field_3}</div>
          <div className="report-item">{report.fields.field_6}</div>
        </div>
      ))}
    </div>
  );
};

export default ReportsGrid;
