// Navigation.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ReportsDD = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <div className="navigation" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span className="nav-item">Reports</span>
      {showDropdown && (
        <div className="dropdown">
          <ul>
          <li>
            <Link to="/reports" className="dropdown-item">Reports</Link>
          </li>
          <li>
            <Link to="/complaints" className="dropdown-item">Complaints</Link>
          </li>

          {/* Add other regions */}
        </ul>
        </div>
      )}
    </div>
  );
};

export default ReportsDD;
