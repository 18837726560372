import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {getStorage} from '../Components/Storage.js';
import '../CSS/Umbrella.css';
import UmbrellaReportsGrid from './UmbrellaReportsGrid.js'

const YourComponent = () => {
  const { companyName } = useParams();
  const [clients, setClients] = useState([]);
  const [cansee, setcansee] = useState([]);


  const username = getStorage('username');

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [groupResponsible, setGroupResponsible] = useState('');
  const [targetedEntity, setTargetedEntity] = useState('');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [selectedDropdownFilter, setSelectedDropdownFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedIndustryFilter, setSelectedIndustryFilter] = useState('');


  useEffect(() => {
    // Fetch client data from your server
    fetch('/api/hello')
      .then((response) => response.json())
      .then((data) => {
        setClients(data.clients);     
      })
      .catch((error) => {
        console.error('Error fetching client data:', error);
      });
  }, []);

  // Logic that depends on 'clients' data
  useEffect(() => {
    if (clients.length > 0) {
      const matchingClient = clients.find(client => client.fields.Email === username);
      if (matchingClient) {
        const isSpecCompany = matchingClient.fields.SpecCompany;
        if (isSpecCompany && matchingClient.fields.Email === username && matchingClient.fields.Company === companyName) {
          setcansee(true);
        }
        else {
            setcansee(false);
        }
      }
    }
  }, [clients, username, companyName]);


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSectorChange = (event) => {
    const sector = event.target.value;
    setSelectedSector(sector === selectedSector ? '' : sector); // Toggle the sector
  };

  const handleGroupResponsibleChange = (event) => {
    setGroupResponsible(event.target.value);
  };

  const handleTargetedEntityChange = (event) => {
    setTargetedEntity(event.target.value);
  }

  const handleClear = () => {
    setSearchTerm('');
    setSelectedSector('');
    setGroupResponsible('');
    setTargetedEntity('');
    setSelectedDropdownFilter('');
    setStartDate('');
    setEndDate('');
    setSelectedIndustryFilter('');
  };

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  const applyDropdownFilter = (event) => {
    setSelectedDropdownFilter(event.target.value);
    // Apply logic based on the selected dropdown filter (e.g., trigger API call with the filter)
    console.log(`Applying dropdown filter: ${event.target.value}`);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  }

    const applyIndustryFilter = (event) => {
    setSelectedIndustryFilter(event.target.value);
    // Apply logic based on the selected industry filter (e.g., trigger API call with the filter)
    console.log(`Applying industry filter: ${event.target.value}`);
  };

  return (
    <div className="umbrella">

  {cansee !== null ? (
    // Display content based on 'canSee' value
    cansee ? (
      // Content when 'canSee' is true
      <div className="title">
              <h2>Reports for {companyName}</h2>
              <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20vh', // This sets the height to the full viewport height
    marginTop: '5vh',
    marginBottom: '7vh'
  }}>
  {/* Search and Sector filter */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
    {/* Search input */}
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <h3>Search:</h3>
      <input
        type="text"
        placeholder="Search by Title"
        value={searchTerm}
        onChange={handleSearch}
      />
 
      {/* Sector filter */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h3>Sector Filter:</h3>
        {/* ... Radio buttons for sectors */}
        <label style={{ color: selectedSector !== 'Animal Rights' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Animal Rights"
              checked={selectedSector === 'Animal Rights'}
              onChange={handleSectorChange}
            />
            <span>Animal Rights</span>
          </label>
          <label style={{ color: selectedSector !== 'Energy Sector' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Energy Sector"
              checked={selectedSector === 'Energy Sector'}
              onChange={handleSectorChange}
            />
            <span>Energy Sector</span>
          </label>
          <label style={{ color: selectedSector !== 'Pharmaceuticals' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Pharmaceuticals"
              checked={selectedSector === 'Pharmaceuticals'}
              onChange={handleSectorChange}
            />
            <span>Pharma</span>
          </label>
          <label style={{ color: selectedSector !== 'Critical Insights' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Critical Insights"
              checked={selectedSector === 'Critical Insights'}
              onChange={handleSectorChange}
            />
            <span>Critical Insights</span>
          </label>
      </div>
    </div>
 
    {/* Clear button */}
    <button onClick={handleClear} className="clear-button">Clear</button>
  </div>
{/* Advanced search row 1 */}
<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
    {/* Group Responsible search */}
    <label>
      Group Responsible:&nbsp;
      <input
        type="text"
        placeholder="Search by group"
        value={groupResponsible}
        onChange={handleGroupResponsibleChange}
      />
    </label>
 
    {/* Targeted Entity search */}
    <label>
      Targeted Entity:&nbsp;
      <input
        type="text"
        placeholder="Search by targeted entity"
        value={targetedEntity}
        onChange={handleTargetedEntityChange}
      />
    </label>
 
    {/* Type of Report dropdown */}
    <label htmlFor="dropdownFilter">Type of Report:</label>
    <select id="dropdownFilter" value={selectedDropdownFilter} onChange={applyDropdownFilter}>
      <option value="">Select an option</option>
      <option value="protest planned">Protest Planned</option>
      <option value="protest after-action">Protest After-Action</option>
      <option value="direct action">Direct Action</option>
      <option value="general intel">General Intel</option>
      <option value="infiltration">Infiltration</option>
    </select>
  </div>
 
  {/* Advanced search row 2 */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginTop: '1rem', marginBottom: '1rem' }}>
        {/* Targeted Industry dropdown */}
        <label htmlFor="industryFilter">Targeted Industry:</label>
        <select id="industryFilter" value={selectedIndustryFilter} onChange={applyIndustryFilter}>
            <option value="">Select an option</option>
            <option value="Fur and Leather">Fur and Leather</option>
            <option value="Hunting">Hunting</option>
            <option value="Research – CRO">Research – CRO</option>
            <option value="Research – Academic">Research – Academic</option>
            <option value="Research – Breeder">Research – Breeder</option>
            <option value="Research – General">Research – General</option>
            <option value="Research – Pharmaceuticals">Research – Pharmaceuticals</option>
            <option value="Research – Transportation">Research – Transportation</option>
            <option value="Research – Government">Research – Government</option>
            <option value="Research – Cosmetics">Research – Cosmetics</option>
            <option value="Research – Other">Research – Other</option>
            <option value="Agriculture – General">Agriculture – General</option>
            <option value="Agriculture – Transportation">Agriculture – Transportation</option>
            <option value="Agriculture – Government">Agriculture – Government</option>
            <option value="Agriculture – Food Services">Agriculture – Food Services</option>
            <option value="Agriculture – Farms">Agriculture – Farms</option>
            <option value="Agriculture – Processing">Agriculture – Processing</option>
            <option value="Entertainment – Government">Entertainment – Government</option>
            <option value="Entertainment – Sports">Entertainment – Sports</option>
            <option value="Entertainment – Zoo/Park">Entertainment – Zoo/Park</option>
            <option value="Pet Industry">Pet Industry</option>
            <option value="Coal">ES - Coal</option>
            <option value="Logging">ES - Logging</option>
            <option value="Academic">ES - Academic</option>
            <option value="Financing">ES - Financing</option>
            <option value="Government">ES - Government</option>
            <option value="Fossil Fuels">ES - Fossil Fuels</option>
            <option value="Nuclear">ES - Nuclear</option>
            <option value="Renewables">ES - Renewables</option>
            <option value="Anti-Vaccine">Pharma - Anti-Vaccine</option>
            <option value="Access to Medicine">Pharma - Access to Medicine</option>
            <option value="GMO">Pharma - GMO</option>
            {/* Add more options as needed */}
          </select>
 
    {/* Start Date filter */}
    <label>
      Start Date:&nbsp;
      <input
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
      />
    </label>
 
    {/* End Date filter */}
    <label>
      End Date:&nbsp;
      <input
        type="date"
        value={endDate}
        onChange={handleEndDateChange}
      />
    </label>
 
  </div>
</div>
        <UmbrellaReportsGrid searchTerm={searchTerm} 
        selectedSector={selectedSector} 
        groupResponsible={groupResponsible}
        targetedEntity={targetedEntity}
        selectedDropdownFilter={selectedDropdownFilter}
        compName = {companyName}
        startDate={startDate}
        endDate={endDate}
        selectedIndustryFilter={selectedIndustryFilter}
        />
      </div>
    ) : (
      // Content when 'canSee' is false
      <div>
        <p>You don't have access to view this content.</p>
      </div>
    )
  ) : (
    // No access, no content displayed
    null
  )}
</div>
  );
};

export default YourComponent;
