
const Privacy = () => {
    return (
    <div>
        <h1>Privacy</h1>
        <p>The privacy policy will go here once it is written</p>
     </div>
    );

}

export default Privacy;