import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../CSS/Calendar.css';

const localizer = momentLocalizer(moment);

const YourCalendarComponent = () => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    // Fetch reports data from your server
    fetch('/api/hello')
      .then((response) => response.json())
      .then((data) => {
        // Filter reports where report.fields.field_9 is "Protest Planned"
        const filteredReports = data.reports.filter((report) => report.fields.field_9 === "Protest Planned");
        
        setReports(filteredReports);
      })
      .catch((error) => {
        console.error('Error fetching reports data:', error);
      });
  }, []);


  const events = reports.map((report) => ({
    title: report.fields.Title,
    start: moment(report.fields.field_2_formatted).toDate(),
    end: moment(report.fields.field_2_formatted).toDate(),
    url: report.fields.Links1, // Assuming 'link' is the field containing the URL
  }));

  const EventComponent = ({ event }) => {
    return (
      <div className="report-item">
        <a href={event.url} target="_blank" rel="noopener noreferrer">
          {event.title}
        </a>
      </div>
    );
  };

  return (
    <div className="calendar">
      <h1>Planned Protests</h1>
    <div className="calendar-wrapper">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={{ month: true }} // Set timeslots to 1 for the day view
        defaultDate={new Date()}
        popup // Enable popup for event details
        components={{ event: EventComponent }} // Set custom component for event rendering
      />
    </div>
    </div>
  );
};

export default YourCalendarComponent;
